import { initializeApp } from 'firebase/app';
import { getAuth, connectAuthEmulator, setPersistence, browserLocalPersistence } from 'firebase/auth';

const isDevelopment = process.env.APP_ENV === 'development';
const disableWarnings = true;

export default (context, inject) => {
    const config = {
        apiKey: process.env.FIREBASE_API_KEY,
        authDomain: process.env.FIREBASE_AUTH_DOMAIN,
        databaseURL: process.env.FIREBASE_DATABASE_URL,
        projectId: process.env.FIREBASE_PROJECT_ID,
        storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.FIREBASE_APP_ID,
        measurementId: process.env.FIREBASE_MEASUREMENT_ID
    };

    const app = initializeApp(config);
    const auth = getAuth(app);

    setPersistence(auth, browserLocalPersistence);

    if (isDevelopment) {
        connectAuthEmulator(auth, 'http://localhost:9099', { disableWarnings });
    }

    inject('firebase', app);
    inject('auth', auth);
}
